import React from 'react'
import { Link } from 'react-router-dom'
import 'react-pro-sidebar/dist/css/styles.css'
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from 'react-pro-sidebar'
import Social from '../../social/Social'

const MobileMenu = () => {
  return (
    <>
      <div className="ptf-offcanvas-menu__navigation">
        <ProSidebar>
          <SidebarContent>
            <Menu className="sidebar-menu_wrapper">
              <Link to="/">
                <SubMenu title="Home" />
              </Link>
              <Link to="/about">
                <SubMenu title="About" />
              </Link>
              <Link to="/portfolio">
                <SubMenu title="Portfolio" />
              </Link>
              <Link to="/services">
                <SubMenu title="Services" />
              </Link>
              <Link to="/contact">
                <SubMenu title="Contact" />
              </Link>
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
      {/* End .ptf-offcanvas-menu__navigation */}

      <div className="ptf-offcanvas-menu__footer">
        <p className="ptf-offcanvas-menu__copyright">
          @{new Date().getFullYear()} <span>Highline Developers</span>. All
          Rights Reserved. <br />.
        </p>
        <Social />
      </div>
      {/* End .ptf-offcanvas-menu__footer */}
    </>
  )
}

export default MobileMenu
