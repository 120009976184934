import React from 'react'
import { Helmet } from 'react-helmet'
import BlogComment from '../../components/blog/BlogComment'
import BlogCommentForm from '../../components/blog/BlogCommentForm'
import BlogPostAuthor from '../../components/blog/BlogPostAuthor'
import RelatedPost from '../../components/blog/RelatedPost'
import CopyRight from '../../components/footer/copyright/CopyRight'
import Footer from '../../components/footer/Footer'
import HeaderDefault from '../../components/header/HeaderDefault'
import ImageGridTwo from '../../components/image-grid/ImageGridTwo'
import SocialFour from '../../components/social/SocialFour'

const Privacy = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--blog-grid">
      <Helmet>
        <title>Highline Developers - Privacy Policy</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderDefault />
        {/* End  HeaderHomeDefault */}

        <div className="main">
          <article className="ptf-single-post">
            {/* <!--Post Header--> */}
            <header className="ptf-single-post__header ptf-single-post__header--style-1">
              <div className="container-xxl">
                <h1 className="ptf-single-post__title">Privacy Policy</h1>
                <div className="ptf-single-post__meta">
                  <span className="cat">Effective as of</span>
                  <span className="date">April 7, 2020</span>
                </div>
              </div>
            </header>

            {/* <!--Post Media--> */}
            {/* <div className="ptf-single-post__media">
              <div className="container-xxl">
                <img
                  src="assets/img/blog/single-post/post-media-1.png"
                  alt="blog post"
                  loading="lazy"
                />
              </div>
            </div> */}

            {/* <!--Post Wrapper--> */}
            <div className="ptf-single-post__wrapper">
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    {/* <!--Post Info--> */}
                    {/* <div className="ptf-single-post__info">
                      <a className="author" href="#">
                        <i className="lnil lnil-user"></i>by <span>Admin</span>
                      </a>
                      <a className="view" href="#">
                        <i className="lnil lnil-eye"></i>5,6k Views
                      </a>
                      <a className="comments" href="#">
                        <i className="lnil lnil-comments"></i>3 Comments
                      </a>
                      <a className="report" href="#">
                        <i className="lnil lnil-warning"></i>Report
                      </a>
                    </div> */}

                    {/* <!--Post Excerpt--> */}
                    <div className="ptf-single-post__excerpt">
                      This Privacy Policy applies as between you, the User of
                      this Website and the owner and provider of this Web Site.
                      This Policy applies to our use of any and all Data
                      collected by us in relation to your use of the Web Site
                      and any Services or Systems therein.
                    </div>

                    {/* <!--Post Content--> */}
                    <div className="ptf-single-post__content">
                      <p>
                        <span className="has-accent-1">Our Promises:</span> This
                        policy applies as between you, and We the owner and
                        provider of this website. This policy applies to our use
                        of any and all data collected by us in relation to your
                        use of the website and any services or systems therein.
                        Our promises to you and any data you provide us: We will
                        ensure your data is protected and your privacy is kept.
                        We will only collect and process your data when
                        necessary. We will not sell, let, or in any way
                        distribute or, make public your personal information. We
                        will not spam you, we despise this as much as you do!
                      </p>
                      <p>
                        <span className="has-accent-1">
                          Definitions and Interpretation In this Policy:
                        </span>{' '}
                        the following terms shall have the following meanings:
                        “Account” means collectively the personal information,
                        Payment Information and credentials used by Users to
                        access Material and/or any communications System on the
                        Web Site; “Content” means any text, graphics, images,
                        audio, video, software, data compilations and any other
                        form of information capable of being stored in a
                        computer that appears on or forms part of this Web Site;
                        “Cookie” means a small text file placed on your computer
                        by We when you visit certain parts of this Web Site.
                        This allows us to identify recurring visitors and to
                        analyse their browsing habits within the Web Site. Where
                        e-commerce facilities are provided, Cookies may be used
                        to store your Browsing History. Further details are
                        contained in Clause 10 and [Schedule 1] OR [Schedules 1
                        and 2] of this Policy; “Data” means collectively all
                        information that you submit to the Web Site. This
                        includes, but is not limited to, Account details and
                        information submitted using any of our Services or
                        Systems; “Software Academy Ltd” means We, d “Service”
                        means collectively any online facilities, tools,
                        services or information that We makes available through
                        the Web Site either now or in the future; “System” means
                        any online communications infrastructure that Software
                        Academy Ltd makes available through the Web Site either
                        now or in the future. This includes, but is not limited
                        to, web-based email, message boards, live chat
                        facilities and email links; “User” / “Users” means any
                        third party that accesses the Web Site and is not
                        employed by We and acting in the course of their
                        employment; and “Web Site” means the website that you
                        are currently using (https://highlinedev.co) and any
                        sub-domains of this site (e.g.
                        news.https://highlinedev.co) unless expressly excluded
                        by their own terms and conditions.
                      </p>
                      <p>
                        <span className="has-accent-1">
                          Personal Data we would collect from you:
                        </span>{' '}
                        Personal Details, such as: Name, Gender, Job Title,
                        Profession Contact Information, such as: Email
                        Addresses, Telephone Numbers Demographic Information,
                        such as: Postcode, Preferences and Interests Financial
                        Information, such as: Credit / Debit Card Numbers Your
                        Website Interaction Information, such as: IP Address,
                        Geological Location, Device and Operating System,
                        Internet Browser Type and Version, URLs with regards to
                        how you came to this website, your activity on this
                        website and where you exit to
                      </p>
                      <p>
                        <span className="has-accent-1">
                          Why we would collect your Personal Data:
                        </span>{' '}
                        The above data may be required by us from time to time
                        in order to provide you with the best possible service
                        and experience when using our website. Specifically, the
                        collected data may be used by us for the following
                        reasons: internal record keeping; improvement of our
                        products/services; transmission by email of promotional
                        materials that may be of interest to you; contact for
                        market research purposes which may be done using email,
                        telephone, fax or mail. Such information may be used to
                        customise or update the Website.
                      </p>
                      <p>
                        <span className="has-accent-1">Data Collected:</span>{' '}
                        Without limitation, any of the following Data may be
                        collected: 2.1 name; 2.2 date of birth; 2.3 gender; 2.4
                        job title; 2.5 profession; 2.6 contact information such
                        as email addresses and telephone numbers; 2.7
                        demographic information such as postcode, preferences
                        and interests; 2.8 financial information such as
                        credit/debit card numbers; 2.9 IP address (automatically
                        collected); 2.10 web browser type and version
                        (automatically collected); 2.11 operating system
                        (automatically collected); 2.12 a list of URLs starting
                        with a referring site, your activity on this Web Site,
                        and the site you exit to (automatically collected); and
                        2.13 Cookie information (see Clause 10 below).
                      </p>
                      <p>
                        <span className="has-accent-1">Our Use of Data</span>{' '}
                        3.1 Any personal Data you submit will be retained by We
                        for as long as you use the Services and Systems provided
                        on the Web Site. Data that you may submit through any
                        communications System that we may provide may be
                        retained for a longer period of up to 6 months. 3.2
                        Unless we are obliged or permitted by law to do so, and
                        subject to Clause 4, your Data will not be disclosed to
                        third parties. This does include our affiliates and / or
                        other companies within our group. 3.3 All personal Data
                        is stored securely in accordance with the principles of
                        the Data Protection Act 1998. For more details on
                        security, see Clause 9 below. 3.4 Any or all of the
                        above Data may be required by us from time to time in
                        order to provide you with the best possible service and
                        experience when using our Web Site. Specifically, Data
                        may be used by us for the following reasons: 3.4.1
                        internal record keeping; 3.4.2 improvement of our
                        products/services; 3.4.3 transmission by email of
                        promotional materials that may be of interest to you;
                        3.4.4 contact for market research purposes which may be
                        done using email, telephone, fax or mail. Such
                        information may be used to customise or update the Web
                        Site.
                      </p>
                      <p>
                        <span className="has-accent-1">
                          How we store and keep your Personal Data secure
                        </span>{' '}
                        Unless we are obliged or, permitted to do so by law and
                        subject to Clause 6.0 below, your data will not be
                        disclosed to third parties. This does include our
                        affiliates and/or other companies within our group. All
                        personal data is stored securely in accordance with the
                        principles of the Data Protection Act 1998. Data
                        security is of great importance to us and to protect
                        your data we have put in place suitable physical,
                        electronic and managerial procedures to safeguard and
                        secure data collected online. Specifically, we utilise
                        the following systems: admin IP whitelisting two-step
                        authentication regular software updates enforce strong
                        passwords educate clients on security
                      </p>
                      <p>
                        <span className="has-accent-1">
                          Third-Party Web Sites and Services:
                        </span>{' '}
                        Unless we are obliged or, permitted to do so by law and
                        subject to Clause 6.0 below, your data will not be
                        disclosed to third parties. This does include our
                        affiliates and/or other companies within our group. All
                        personal data is stored securely in accordance with the
                        principles of the Data Protection Act 1998. Data
                        security is of great importance to us and to protect
                        your data we have put in place suitable physical,
                        electronic and managerial procedures to safeguard and
                        secure data collected online. Specifically, we utilise
                        the following systems: admin IP whitelisting two-step
                        authentication regular software updates enforce strong
                        passwords educate clients on security
                      </p>
                      <p>
                        <span className="has-accent-1">
                          Third-Party Web Sites and Services:
                        </span>{' '}
                        We may, from time to time, employ the services of other
                        parties for dealing with matters that may include, but
                        are not limited to, payment handling, delivery of
                        purchased items, search engine facilities, advertising
                        and marketing. The providers of such services do have
                        access to certain personal Data provided by Users of
                        this Web Site. Any Data used by such parties is used
                        only to the extent required by them to perform the
                        services that We requests. Any use for other purposes is
                        strictly prohibited. Furthermore, any Data that is
                        processed by third parties must be processed within the
                        terms of this Policy and in accordance with the Data
                        Protection Act 1998.
                      </p>
                      <p>
                        <span className="has-accent-1">
                          How long do we keep your Personal Data:
                        </span>{' '}
                        Any personal data you submit will be retained by us for
                        as long as you use the services and systems provided on
                        the website. The data that you may submit through any
                        communications system, that we may provide, may be
                        retained for a longer period of up to 12 months.
                      </p>
                      <p>
                        {' '}
                        <span className="has-accent-1">
                          Usage of Third Party Websites and Services with
                          regards to your Personal Data:{' '}
                        </span>{' '}
                        We may, from time to time, employ the services of other
                        parties for dealing with matters that may include, but
                        are not limited to: Payment handling Delivery of
                        purchased items Search engine facilities Advertising
                        Marketing The providers of such services do have access
                        to certain personal data provided by Users of this
                        website Any data used by such parties is used only to
                        the extent required by them to perform the services that
                        we request. Any use for other purposes is strictly
                        prohibited. Furthermore, any data that is processed by
                        third parties must be processed within the terms of this
                        policy and in accordance with the General Data
                        Protection Regulation 2018 (GDPR).
                      </p>
                      <p>
                        {' '}
                        <span className="has-accent-1">
                          Changes of Business Ownership and Control:{' '}
                        </span>{' '}
                        We may, from time to time, expand or reduce its business
                        and this may involve the sale of certain divisions or
                        the transfer of control of certain divisions to other
                        parties. Data provided by Users will, where it is
                        relevant to any division so transferred, be transferred
                        along with that division and the new owner or newly
                        controlling party will, under the terms of this Policy,
                        be permitted to use the Data for the purposes for which
                        it was supplied by you. 5.2 In the event that any Data
                        submitted by Users will be transferred in such a manner,
                        you will not be contacted in advance and informed of the
                        changes.
                      </p>
                      <p>
                        <span className="has-accent-1">
                          Controlling Access to your Data
                        </span>{' '}
                        Wherever you are required to submit Data, you will be
                        given options to restrict our use of that Data. This may
                        include the following: 6.1.1 use of Data for direct
                        marketing purposes; and 6.1.2 sharing Data with third
                        parties.
                      </p>
                      <p>
                        <span className="has-accent-1">
                          How you can control our access to your Personal Data:
                        </span>{' '}
                        Wherever you are required to submit Data, you will be
                        given options to restrict our use of that Data. This may
                        include the following: Use of Data for direct marketing
                        purposes Sharing Data with third parties. You may also
                        have access to certain areas of the website without
                        providing any Personal Data at all. However, to use all
                        services and systems available on the website you may be
                        required to submit Account Information or other data.
                        You may restrict your internet browser’s use of cookies.
                        For more information see Clause 9.0 below.
                      </p>
                      <p>
                        {' '}
                        <span className="has-accent-1">
                          Your Right to Withhold Information
                        </span>{' '}
                        You may access certain areas of the Web Site without
                        providing any Data at all. However, to use all Services
                        and Systems available on the Web Site you may be
                        required to submit Account information or other Data.
                        7.2 You may restrict your internet browser’s use of
                        Cookies. For more information see Clause 10 below.
                      </p>
                      <p>
                        <span className="has-accent-1">
                          How you can access your Personal Data:
                        </span>{' '}
                        You may access your account at any time to view or amend
                        the data. You may need to modify or update your Data if
                        your circumstances change. Additional data as to your
                        marketing preferences may also be stored and you may
                        change this at any time. You can ask us whether we are
                        keeping Personal Data about you in the following forms.
                        Upon request, we will provide you with a readable copy
                        of the personal data which we keep about you, although
                        we may require proof of your identity. We allow you to
                        challenge the data that we hold about you and, where
                        appropriate, you may have the data amended, erased or
                        completed.
                      </p>
                      <p>
                        <span className="has-accent-1">
                          Accessing your own Data
                        </span>{' '}
                        You may access your Account at any time to view or amend
                        the Data. You may need to modify or update your Data if
                        your circumstances change. Additional Data as to your
                        marketing preferences may also be stored and you may
                        change this at any time. You can ask us whether we are
                        keeping personal data about you by sending postal mail
                        to this address – We,. Upon request, we will provide you
                        with a readable copy of the personal data which we keep
                        about you, although we may require proof of your
                        identity. We will provide the information upon payment
                        of the current statutory fee, which is £10. We allow you
                        to challenge the data that we hold about you and, where
                        appropriate, you may have the data amended, erased or
                        completed. Security 9.1 Data security is of great
                        importance to We and to protect your Data we have put in
                        place suitable physical, electronic and managerial
                        procedures to safeguard and secure Data collected
                        online. 9.2 Specifically we utilise the following
                        systems: 9.2.1 admin IP whitelisting
                      </p>
                      <p>
                        {' '}
                        <span className="has-accent-1">Cookies: </span> We may
                        set and access first-party Cookies on your computer.
                        Cookies that may be placed on your computer are detailed
                        in Schedule 1 to this Policy. These Cookies are integral
                        to the services provided by the Web Site to you. 10.2
                        You can choose to enable or disable Cookies in your web
                        browser. By default, your browser will accept Cookies,
                        however, this can be altered. For further details please
                        consult the help menu in your browser. Disabling Cookies
                        may prevent you from using the full range of Services
                        available on the Web Site. 10.3 You may delete Cookies,
                        however, you may lose any information that enables you
                        to access the Web Site more quickly. 10.4 [The Web Site
                        uses the third-party Cookies detailed in Schedule 2 to
                        this Policy for the purposes described therein. These
                        Cookies are not integral to the services provided by the
                        Web Site to you and maybe blocked at your choosing via
                        your internet browser’s privacy settings. Please ensure
                        that your internet browser is up-to-date and consult the
                        help and guidance provided by the developer of your
                        browser if you are unsure as to how to adjust your
                        privacy settings.]
                      </p>
                      <p>
                        <span className="has-accent-1">
                          How we use Cookies:
                        </span>{' '}
                      </p>
                      We may set and access first-party Cookies on your
                      computer. cookies that may be placed on your computer are
                      detailed in Schedule 1 to this Policy. These cookies are
                      integral to the services provided by the website to you.
                      You can choose to enable or disable cookies in your web
                      browser. By default, your browser will accept cookies,
                      however, this can be altered. For further details please
                      consult the help menu in your browser. Disabling cookies
                      may prevent you from using the full range of services
                      available on the website. You may delete cookies, however,
                      you may lose any information that enables you to access
                      the website more quickly. [The website uses the
                      third-party Cookies detailed in Schedule 2 to this Policy
                      for the purposes described therein. These cookies are not
                      integral to the services provided by the website to you
                      and maybe blocked at your choosing via your internet
                      browser’s privacy settings. Please ensure that your
                      internet browser is up-to-date and consult the help and
                      guidance provided by the developer of your browser if you
                      are unsure as to how to adjust your privacy settings.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Privacy
