import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Award from '../../components/award/Award'
import Blog from '../../components/blog/Blog'
import Brand from '../../components/brand/Brand'
import Counter from '../../components/counter/Counter'
import CopyRight from '../../components/footer/copyright/CopyRight'
import Footer from '../../components/footer/Footer'
import HeaderHomeDefault from '../../components/header/HeaderHomeDefault'
import HeroDefault from '../../components/hero/HeroDefault'
import Portfolio from '../../components/portfolio/Portfolio'
import Approach from '../../components/service/Approach'
import ServiceOne from '../../components/service/ServiceOne'
import Testimonial from '../../components/testimonial/Testimonial'

const HomeDefault = () => {
  return (
    <div className="ptf-site-wrapper animsition  ptf-is--home-default">
      <Helmet>
        <title>Highline Developers - Digital Agency</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderHomeDefault />
      {/* End Header Default */}

      <div className="ptf-site-wrapper__inner">
        <div className="ptf-main">
          <div className="ptf-page ptf-page--home-default">
            {/*=============================================
                Start Hero Section
              ============================================== */}
            <section className="has-accent-1-background">
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '15rem' }}
              ></div>
              <div className="container-xxl">
                <HeroDefault />
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '12.5rem', '--ptf-md': '6.25rem' }}
              ></div>
            </section>

            {/*=============================================
                Start Service Section
              ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '10rem', '--ptf-md': '5rem' }}
              ></div>
              <div className="container-xxl">
                <div className="row">
                  <div className="col-lg-3">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading">
                        Our <br />
                        Services
                      </h2>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{ '--ptf-xxl': '2.5rem' }}
                      ></div>
                      <p className="fz-18">
                        We help ambitious businesses like yours every day with
                        our talented team experts.
                      </p>
                    </div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{
                        '--ptf-lg': '4.375rem',
                        '--ptf-md': '2.1875rem',
                      }}
                    ></div>
                  </div>
                  <div className="col-lg-8 offset-lg-1">
                    <ServiceOne />
                  </div>
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '10rem', '--ptf-md': '5rem' }}
              ></div>
            </section>

            {/*=============================================
                Start Portfolio Section
              ============================================== */}
            <section>
              <div className="container-xxl">
                <div className="row align-items-center">
                  <div className="col-12 col-md-9">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading d-inline-flex">Works</h2>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 text-md-end">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ '--ptf-md': '1.875rem' }}
                    ></div>
                    {/* <!--Animated Block--> */}
                    <Link
                      className="
                          ptf-link-with-arrow
                          fz-18
                          text-uppercase
                          fw-semibold
                          has-black-color
                          d-none d-lg-inline-flex
                        "
                      to="/portfolio"
                      style={{ marginLeft: '5.625rem' }}
                    >
                      All Projects <i className="lnil lnil-chevron-right"></i>
                    </Link>
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ '--ptf-xxl': '6.25rem' }}
                ></div>
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {/* <!--Content Slider--> */}
                  <Portfolio />
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '10rem', '--ptf-md': '9.5rem' }}
              ></div>
            </section>

            {/*=============================================
                Start Our Approach Section
              ============================================== */}
            <section
              className="has-accent-1-background"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + 'assets/img/root/service-bubble.png'
                })`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '100% calc(100% + 120px)',
              }}
            >
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '10rem', '--ptf-md': '5rem' }}
              ></div>
              <div className="container-xxl">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2 className="h1 large-heading">Our Approach</h2>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ '--ptf-xxl': '7.5rem', '--ptf-md': ' 3.75rem' }}
                ></div>
                <Approach />
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '10rem', '--ptf-md': '5rem' }}
              ></div>
            </section>

            {/*=============================================
                Start Brand and Counterup Section
              ============================================== */}

            <section>
              {/* <!--Spacer--> */}

              <div className="container-xxl">
                <div className="row">
                  <div className="col-12">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ '--ptf-xxl': '8.75rem', '--ptf-md': '4.375rem' }}
                    ></div>
                    {/* <!--Divider--> */}
                    <div className="ptf-divider"></div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ '--ptf-xxl': '6.25rem', '--ptf-md': '3.125rem' }}
                    ></div>
                  </div>
                </div>
                {/* End .row */}

                <Counter />
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '10rem', '--ptf-md': '5rem' }}
              ></div>
            </section>

            {/*=============================================
                Start Jaralax Testimonial Section
              ============================================== */}
            <section
              className="jarallax jarallax-img"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL +
                  'assets/img/about-us-testimonial-background.png'
                })`,
              }}
            >
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '12.5rem', '--ptf-md': '6.25rem' }}
              ></div>
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-4">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <img
                        src="assets/img/root/quote.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ '--ptf-xl': '3.75rem' }}
                    ></div>
                  </div>
                  <div className="col-xl-8">
                    <Testimonial />
                  </div>
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '12.5rem', '--ptf-md': '6.25rem' }}
              ></div>
            </section>

            {/*=============================================
                Start Award Section
              ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '11.875rem', '--ptf-md': '5.9375rem' }}
              ></div>
              <div className="container-xxl">
                <div className="row">
                  <div className="col-lg-4">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading">
                        Our
                        <br />
                        Awards
                      </h2>
                    </div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ '--ptf-lg': '3.75rem' }}
                    ></div>
                  </div>
                  <div className="col-lg-8">
                    <Award />
                  </div>
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '10rem', '--ptf-md': '5rem' }}
              ></div>
            </section>

            <section>
              <div className="container-xxl">
                {/* <!--Divider--> */}
                <div className="ptf-divider"></div>
              </div>
            </section>

            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '10rem', '--ptf-md': '5rem' }}
              ></div>
              <div className="container-xxl">
                {/* <!--Animated Block--> */}
                {/* <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <div
                    className="row"
                    style={{
                      '--bs-gutter-x': '3.75rem',
                      '--bs-gutter-y': '7.5rem',
                    }}
                  >
                    <Blog />
                  </div>
                </div> */}
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ '--ptf-xxl': '10rem', '--ptf-md': '5rem' }}
              ></div>
            </section>

            {/*=============================================
                End Blog Section
              ============================================== */}
          </div>
        </div>
        {/* End .ptf-main */}

        {/* <!--Footer--> */}
        <footer className="ptf-footer ptf-footer--style-1">
          <div className="container-xxl">
            <div className="ptf-footer__top">
              <Footer />
            </div>
            <div className="ptf-footer__bottom">
              <CopyRight />
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default HomeDefault
