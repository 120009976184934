import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

const portfolioContent = [
  {
    img: 'work-1',
    categorie: 'Front-End & Back-End Overhaul',
    title: 'Arkivum',
  },
  {
    img: 'work-2',
    categorie: 'Product',
    title: 'Pechy Properties',
  },
  {
    img: 'work-3',
    categorie: 'Branding, Packaging',
    title: 'Fallow Resorts',
  },
  {
    img: 'work-4',
    categorie: 'Illustration',
    title: 'De Havilland Gin',
  },
]

const Portfolio = () => {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    centerPadding: '0',
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  }

  return (
    <div className="ptf-content-slider swiper-container slide-portfolio">
      <div className="swiper-wrapper">
        <Slider {...settings}>
          {/* <!--Portfolio Item--> */}
          {portfolioContent.map((item, i) => (
            <article className="ptf-work ptf-work--style-3" key={i}>
              <div className="ptf-work__media">
                <Link to="" className="ptf-work__link"></Link>
                <img
                  src={`assets/img/portfolio/${item.img}.png`}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="ptf-work__meta">
                <div className="ptf-work__category">{item.categorie}</div>
                <h4 className="ptf-work__title">
                  <Link to="">{item.title}</Link>
                </h4>
              </div>
            </article>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default Portfolio
