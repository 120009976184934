import React from 'react'

// Homepage Demo
import HomeDefault from '../views/all-home-version/HomeDefault'

// Service
import Service from '../views/inner-pages/service/Service'
// import ServiceDetails from '../views/inner-pages/service/ServiceDetails'

// About
import AboutUs from '../views/inner-pages/about/AboutUs'

// Blog
import Terms from '../views/inner-pages/Terms'
import Privacy from '../views/inner-pages/Privacy'
import BlogDetails from '../views/inner-pages/Terms'

// Portfolio
import WorksGrid from '../views/inner-pages/portfolio/WorksGrid'

// Others
import Contact from '../views/inner-pages/Contact'
// import Terms from '../views/inner-pages/Terms'
// import Privacy from '../views/inner-pages/Privacy'

import NotFound from '../views/NotFound'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ScrollTopBehaviour from '../components/ScrollTopBehaviour'

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/" component={HomeDefault} />
          {/* Service */}
          <Route path="/services" component={Service} />
          {/* <Route path="/service-details" component={ServiceDetails} /> */}
          {/* About */}
          <Route path="/about" component={AboutUs} />

          {/* Blog */}
          {/* <Route path="/blog" component={BlogGrid} /> */}

          {/* Portfolio */}
          <Route path="/portfolio" component={WorksGrid} />
          {/* others */}
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/contact" component={Contact} />

          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  )
}

export default Routes
