import React from 'react'
import { Helmet } from 'react-helmet'
import BlogComment from '../../components/blog/BlogComment'
import BlogCommentForm from '../../components/blog/BlogCommentForm'
import BlogPostAuthor from '../../components/blog/BlogPostAuthor'
import RelatedPost from '../../components/blog/RelatedPost'
import CopyRight from '../../components/footer/copyright/CopyRight'
import Footer from '../../components/footer/Footer'
import HeaderDefault from '../../components/header/HeaderDefault'
import ImageGridTwo from '../../components/image-grid/ImageGridTwo'
import SocialFour from '../../components/social/SocialFour'

const Terms = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--blog-grid">
      <Helmet>
        <title>Highline Developers - Terms & Conditions</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderDefault />
        {/* End  HeaderHomeDefault */}

        <div className="main">
          <article className="ptf-single-post">
            {/* <!--Post Header--> */}
            <header className="ptf-single-post__header ptf-single-post__header--style-1">
              <div className="container-xxl">
                <h1 className="ptf-single-post__title">Terms & Conditions</h1>
                <div className="ptf-single-post__meta">
                  <span className="cat">Effective as of</span>
                  <span className="date">April 7, 2020</span>
                </div>
              </div>
            </header>

            {/* <!--Post Media--> */}
            {/* <div className="ptf-single-post__media">
              <div className="container-xxl">
                <img
                  src="assets/img/blog/single-post/post-media-1.png"
                  alt="blog post"
                  loading="lazy"
                />
              </div>
            </div> */}

            {/* <!--Post Wrapper--> */}
            <div className="ptf-single-post__wrapper">
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    {/* <!--Post Info--> */}
                    {/* <div className="ptf-single-post__info">
                      <a className="author" href="#">
                        <i className="lnil lnil-user"></i>by <span>Admin</span>
                      </a>
                      <a className="view" href="#">
                        <i className="lnil lnil-eye"></i>5,6k Views
                      </a>
                      <a className="comments" href="#">
                        <i className="lnil lnil-comments"></i>3 Comments
                      </a>
                      <a className="report" href="#">
                        <i className="lnil lnil-warning"></i>Report
                      </a>
                    </div> */}

                    {/* <!--Post Excerpt--> */}
                    <div className="ptf-single-post__excerpt">
                      These are our general terms and conditions – please see
                      your{' '}
                      <span project className="has-accent-1">
                        project documentation
                      </span>{' '}
                      and <span className="has-accent-1">contract</span> for
                      terms and conditions specific to a project design and
                      project build.
                    </div>

                    {/* <!--Post Content--> */}
                    <div className="ptf-single-post__content">
                      <p>
                        <span className="has-accent-1">Applicability:</span>{' '}
                        These terms and conditions apply to any work (“the
                        work”) undertaken by Highline Developers (“the Agency”)
                        arising from verbal or written instructions given by a
                        Client (‘the Client’) following a provision of an
                        estimate for a specified number of hours required for
                        completion of the work (“the estimate”). These terms and
                        conditions apply unless a variation has been
                        subsequently agreed and confirmed in writing, signed by
                        an authorised representative of Agency and the Client.
                        The work: the Agency will carry out any and all work
                        requested by and agreed with (verbally or in writing)
                        the Client in consideration for a fee based on the
                        hourly rate set out in the estimate.
                      </p>
                      <p>
                        <span className="has-accent-1">Fee:</span> (i) The
                        applicable rate of fees for the work is hourly rate set
                        out in the estimate. (ii) Any estimates of the whole or
                        any part of the total fee applicable for the work (“the
                        total fee”) is given in good faith and shall be treated
                        as an estimate only and all errors and omissions shall
                        be excepted. (iii) Any estimates of the total fee shall
                        be valid for 30 days from the date of the estimate. (iv)
                        The Agency reserves its right to apply additional
                        charges for any work entailed in altering or modifying
                        the deliverables caused by any changes, amendments or
                        additions requested by the Client after the date of the
                        estimate. Payment: (i) Payment of the fees shall be made
                        in pounds sterling according to the terms of the
                        invoice. (ii) The Agency shall be entitled to charge
                        interest on overdue sums.
                      </p>
                      <p>
                        <span className="has-accent-1">Software:</span> Upon
                        Delivery and provided that the Client has complied with
                        its obligations to pay off all sums due for the work,
                        the Agency grants to the Client a non-exclusive
                        worldwide licence to use any proprietary software of the
                        Agency used in the creation, development and/or
                        operation of the deliverables (including any
                        sub-routines, sub-elements or other generic parts of
                        such software incorporated into the deliverables which
                        constitute the “building blocks” of the underlying code)
                        (the “Agency Software”) solely in connection with the
                        normal operation of the deliverables and, where
                        applicable, in accordance with the Agency’s express
                        instructions. The Agency shall obtain all such usage
                        rights for the Client in respect of any software owned
                        by a third party used in the creation, development
                        and/or operation of the deliverables (the “Third Party
                        Software”) as are deemed necessary by the Agency. For
                        the avoidance of doubt, the Agency’s assignment under
                        this clause 5 to the Client of any copyright and
                        intellectual property rights in the deliverables shall
                        not include the Agency Software, the Third Party
                        Software or any software distributed under the general
                        public license (https://www.gnu.org/licenses/gpl.txt)
                        used by the Agency in the course of the development of
                        the deliverables or incorporated by the Agency into the
                        deliverables.
                      </p>
                      <p>
                        <span className="has-accent-1">Copyright:</span> Subject
                        to the above clause 5 and provided that the Client has
                        complied with its obligations to pay off all sums due to
                        the Agency from the Client, any copyright and
                        intellectual property rights in all any artwork,
                        photograph, layout, copy, designs or any other material
                        created by the Agency under the Agreement shall be
                        assigned to the Client upon Delivery. The Agency
                        reserves the right to use all creative work or materials
                        for its own portfolio or demonstration purposes. The
                        creation of any materials for the Client by the Agency
                        may involve making use of any artwork, photograph,
                        layout, copy, designs or any other material protected by
                        copyright and intellectual property rights belonging to
                        a third party and in existence at the time it is desired
                        to make use of it for the purposes of the Services
                        (“Existing Work”) or any database or methodologies,
                        system or know-how owned, used or created by the Agency,
                        which is also intended for the Client’s use (“Generic
                        Work”). The Agency shall obtain all such usage rights
                        for the Client in respect of the use the Existing Work
                        and Generic Work as are deemed necessary by the Agency.
                        Unless expressly requested and paid for by the Client
                        the copyright in ‘stock’ photographs obtained from news
                        or photographic agencies for particular deliverables or
                        to photographic or film negatives or to any other medium
                        in which this material may be supplied will not be
                        assigned to the Client.
                      </p>
                      <p>
                        <span className="has-accent-1">
                          Confidential Information:
                        </span>{' '}
                        The parties acknowledge a duty, subsisting during and
                        after the termination of this Agreement between the
                        Agency and the Client, not to disclose without the
                        other’s prior written permission any confidential
                        information either concerning the other’s business, its
                        business plans, customers or associated companies. The
                        Agency acknowledges its responsibility to treat in
                        complete confidence all the marketing and sales
                        information and statistics relating to the Client’s
                        business with which the Client may supply the Agency in
                        the course of any work for the Client. The Agency shall
                        impose obligations in terms equivalent to those in this
                        clause 7 on its own personnel. The restrictions in this
                        clause 7 shall not prevent the disclosure or use of
                        information in the proper performance of the Agency’s
                        duties; disclosure as required by law; and the
                        disclosure of information which has come into the public
                        domain other than through unauthorised disclosure.
                      </p>
                      <p>
                        <span className="has-accent-1">
                          Warranties and Indemnities:
                        </span>{' '}
                        (i) All estimate prepared by the Agency are prepared in
                        good faith and on the basis of instructions and
                        information put before the Agency by the Client at the
                        time of preparation. Their suitability or application of
                        effectiveness will depend on the Client, the Client’s
                        staff and subcontractors to the Client and no proposal
                        is to be taken to warrant achievable or attainable
                        results or performance. (ii) The Agency warrants that
                        having taken such legal advice and undertaken such
                        searches as the Agency considers reasonably necessary,
                        to the best of its knowledge and belief any creative
                        work produced by the Agency as part of the Services will
                        be original to its authors, has not been previously
                        published in any form in the United Kingdom (the “UK”)
                        and will not infringe the copyright of any third party
                        in the UK. (iii) The Client warrants that to the best of
                        its knowledge and belief all information supplied to the
                        Agency in pursuance of this Agreement will be accurate,
                        not in any way contrary to English law and will not
                        contain anything obscene, blasphemous, libellous or
                        otherwise unlawful in the UK. The Client warrants that
                        to the best of its knowledge and belief any artwork,
                        photograph, layout, copy, designs or any other material
                        provided to the Agency by the Client for the purposes of
                        incorporation into the deliverables will not infringe
                        any copyright or other intellectual property right of a
                        third party. Should either party or its employees
                        sustain any loss or liability, costs (including legal
                        costs) or damages as a result of the other’s breach of
                        this Agreement, the party in breach shall indemnify the
                        other subject to the provisions of clause
                      </p>
                      <p>
                        <span className="has-accent-1">
                          Limitation of Liability:
                        </span>{' '}
                        Nothing in these Terms and Conditions shall exclude or
                        in any way limit the Agency’s liability for fraud, or
                        for death or personal injury caused by its negligence,
                        or any other liability to the extent such liability may
                        not be excluded or limited as a matter of law. Subject
                        to this but including any liability arising under any
                        indemnity under these Terms and Conditions: (i) the
                        Agency’s maximum aggregate liability under or in
                        connection with the Agreement, whether in contract, tort
                        (including negligence) or otherwise, will in no
                        circumstances exceed £1,000,000 ; and (ii) the Agency
                        will not be liable under these Terms and Conditions of
                        Provision of Service for any loss of actual or
                        anticipated income or profits, loss of contracts or for
                        any special, indirect or consequential loss or damage of
                        any kind howsoever arising and whether caused by tort
                        (including negligence), breach of contract or otherwise,
                        whether or not such loss or damage is foreseeable,
                        foreseen or known.
                      </p>
                      <p>
                        <span className="has-accent-1">Termination:</span>{' '}
                        Either party may terminate this Agreement forthwith by
                        notice in writing to the other if the other party is in
                        material breach of any of the terms of this Agreement
                        and, in the case of a breach capable of remedy, fails to
                        remedy such breach within 28 days of receipt of written
                        notice giving full particulars of the breach and of the
                        steps required to remedy it. Upon the termination of
                        this Agreement and subject to Clauses 3 and 4 of these
                        Terms and Conditions the Agency shall transfer, assign
                        and make available to the Client all property and
                        materials in the possession or control of the Agency
                        belonging to and paid for by the Client.
                      </p>
                      <p>
                        <span className="has-accent-1">Variation:</span> No
                        variation of these Terms and Conditions or of any of the
                        documents referred to in it shall be valid unless agreed
                        by the parties in writing.
                      </p>
                      <p>
                        <span className="has-accent-1">
                          Governing Law and Jurisdiction:
                        </span>{' '}
                        These Terms and Conditions shall be governed by and
                        construed in accordance with the law of United Kingdom.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Terms
