import React from 'react'

const Address = () => {
  return (
    <>
      {/* <!--Animated Block--> */}
      <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">Address</h5>
        {/* <!--Spacer--> */}
        <div className="ptf-spacer" style={{ '--ptf-xxl': '1.25rem' }}></div>
        <p className="fz-20 lh-1p5 has-black-color">
          Medius House, 2 Sheraton St,
          <br />
          London W1F 8BH
        </p>
      </div>

      {/* <!--Spacer--> */}
      <div className="ptf-spacer" style={{ '--ptf-xxl': '2.1875rem' }}></div>

      {/* <!--Animated Block--> */}
      <div className="ptf-animated-block" data-aos="fade" data-aos-delay="100">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">Email</h5>
        {/* <!--Spacer--> */}
        <div className="ptf-spacer" style={{ '--ptf-xxl': '1.25rem' }}></div>
        <p className="fz-20 lh-1p5 has-black-color">
          <a href="mailto:contact@HighlineDev.co">
            contact@HighlineDev.co
            <br />
          </a>
          {/* <a href="mailto:contact@HighlineDev.co">contact@HighlineDev.co</a> */}
        </p>
      </div>

      {/* <!--Spacer--> */}
      <div className="ptf-spacer" style={{ '--ptf-xxl': '2.1875rem' }}></div>

      {/* <!--Animated Block--> */}
      {/* <div className="ptf-animated-block" data-aos="fade" data-aos-delay="200">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">Phone</h5> */}
      {/* <!--Spacer--> */}
      {/* <div className="ptf-spacer" style={{ '--ptf-xxl': '1.25rem' }}></div>
        <p className="fz-20 lh-1p5 has-black-color">
          <a href="tel:+5632356565">+56 3235 65 65</a>
        </p>
      </div> */}

      {/* <!--Spacer--> */}
      <div
        className="ptf-spacer"
        style={{ '--ptf-lg': '4.375rem', '--ptf-md': '2.1875rem' }}
      ></div>
    </>
  )
}

export default Address
