import React from 'react'
import { Link } from 'react-router-dom'

const linkList = [
  {
    itemName: 'Term & Conditions',
    link: '/Terms',
  },
  {
    itemName: 'Privacy Policy',
    link: '/Privacy',
  },
]

const Product = [
  {
    itemName: 'About',
    link: '/about',
  },
  {
    itemName: 'Portfolio',
    link: '/portfolio',
  },
  {
    itemName: 'Services',
    link: '/services',
  },

  {
    itemName: 'Contact',
    link: '/contact',
  },
]

const Footer = () => {
  return (
    <div className="row">
      <div className="col-12 col-xl-6">
        {/* <!--Animated Block--> */}
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <div
            className="ptf-footer-heading has-black-color"
            style={{ maxWidth: '37.5rem' }}
          >
            Made It This Far? Must Have Project In Mind!
          </div>

          <div className="ptf-footer-heading has-black-color">
            Start by{' '}
            <Link
              className="has-accent-1 text-decoration-underline"
              to="/contact"
            >
              Saying Hi!
            </Link>
          </div>

          <p className="fz-24">Medius House, 2 Sheraton St, London W1F 8BH</p>
          <a
            className="fz-40 has-black-color"
            href="mailto:robert.clark@HighlineDev.co"
          >
            contact@HighlineDev.co
          </a>
        </div>
      </div>
      <div className="col-12 col-md-6 col-xl-3">
        {/* <!--Animated Block--> */}
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="100"
        >
          <div className="ptf-widget ptf-widget-links has-black-color">
            <h4 className="ptf-widget-title">Misc</h4>
            <ul>
              {linkList.map((val, i) => (
                <li key={i}>
                  <a href={val.link}>{val.itemName}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-xl-3">
        {/* <!--Animated Block--> */}
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="200"
        >
          <div className="ptf-widget ptf-widget-text">
            <h4 className="ptf-widget-title">Info</h4>
            <div className="ptf-widget ptf-widget-links has-black-color">
              <ul>
                {Product.map((val, i) => (
                  <li key={i}>
                    <a href={val.link}>{val.itemName}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
