import React from 'react'
import { Link } from 'react-router-dom'

const serviceContent = [
  {
    icon: 'lnil lnil-cup',
    titlePart1: 'AWARD WINNING',
    titlePart2: 'TEAM',
    descriptions: ` We source the best talent from around the world adept with the latest technologies.`,
  },
  {
    icon: 'lnil lnil-website-rank',
    titlePart1: 'UI/UX',
    titlePart2: 'SOLUTIONS',
    descriptions: ` Design and user experience takes a keen eye. It's often more art than science and we're passionate about delviering the best for your users.`,
  },
  {
    icon: 'lnil lnil-3d',
    titlePart1: 'SOFTWARE',
    titlePart2: 'DEVELOPMENT',
    descriptions: ` Our developers build solutions from the ground up.`,
  },
  {
    icon: 'lnil lnil-analytics',
    titlePart1: 'DATA DRIVEN',
    titlePart2: 'OUTCOMES',
    descriptions: ` We champion the power of data to provide you with the best outcomes possible based on your needs.`,
  },
]

const ServiceOne = () => {
  return (
    <>
      {serviceContent.map((val, i) => (
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="0"
          key={i}
        >
          {/* <!--Services Box--> */}
          <div className="ptf-service-box">
            {/* <Link
              to="/service-details"
              className="ptf-service-box__link"
            ></Link> */}
            <div className="ptf-service-box__icon">
              <i className={val.icon}></i>
            </div>
            <h5 className="ptf-service-box__title">
              {val.titlePart1} <br />
              {val.titlePart2}
            </h5>
            <div className="ptf-service-box__content">
              <p>{val.descriptions}</p>
            </div>
            <div className="ptf-service-box__arrow">
              <i className="lnil lnil-chevron-right"></i>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default ServiceOne
