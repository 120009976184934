import React from 'react'

const approachContent = [
  {
    subTitle: 'ONE',
    title: 'Research',
    descriptions: `First we make sure we completely understand your needs and understand the best way to deliver a solution.`,
    delayAnimation: '0',
  },

  {
    subTitle: 'TWO',
    title: 'Concept',
    descriptions: `We begin the process of designing and creating design specs based on the project requirements and then get feedback from you to see if we need to adjust course.`,
    delayAnimation: '100',
  },
  {
    subTitle: 'THREE',
    title: 'Strategy',
    descriptions: `Once we know exactly WHAT we will be building with the appropriate technologies when move to what is the best way to build our solution for you.`,
    delayAnimation: '200',
  },
  {
    subTitle: 'FOUR',
    title: 'Development',
    descriptions: `During this stage each week we focus on achieve measurable achievable milestones towards making our solution your reality. During this stage we continue to get feedback as each step of the way we share our progress with you.`,
    delayAnimation: '300',
  },
  {
    subTitle: 'FIVE',
    title: 'Test',
    descriptions: `This is the most important stage to ensure reliability and the integrity of our solution. Through testing we're able to guarentee our work is futureproof.`,
    delayAnimation: '400',
  },
  {
    subTitle: 'SIX',
    title: 'Production',
    descriptions: `The final stage of our process is taking our solution live for your and realising it into the wild.`,
    delayAnimation: '500',
  },
]

const Approach = () => {
  return (
    <div
      className="row"
      style={{
        '--bs-gutter-x': '3.75rem',
        '--bs-gutter-y': '7.5rem',
      }}
    >
      {approachContent.map((val, i) => (
        <div className="col-xl-4 col-lg-6" key={i}>
          {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay={val.delayAnimation}
          >
            {/* <!--Approach Box--> */}
            <div
              className="ptf-approach-box"
              style={{
                '--ptf-title-color': 'var(--ptf-color-white)',
              }}
            >
              <div className="ptf-approach-box__subtitle">{val.subTitle}</div>
              <h3 className="ptf-approach-box__title h1">{val.title}</h3>
              <div className="ptf-approach-box__content">
                <p>{val.descriptions}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Approach
