import React from 'react'
import { Link } from 'react-router-dom'

const portfolioContent = [
  {
    img: 'work-1',
    categorie: 'Front-End & Back-End Overhaul',
    title: 'Arkivium',
  },
  { img: 'work-2', categorie: 'Product', title: 'Pechy Properties' },
  {
    img: 'work-3',
    categorie: 'Branding, Packaging',
    title: 'Fallow Resorts',
  },
  { img: 'work-4', categorie: 'Web Development', title: 'De Havilland Gin' },
]

const RelatedPortfolio = () => {
  return (
    <>
      {portfolioContent.map((val, i) => (
        <div className="col-lg-4" key={i}>
          <div className="grid-item">
            {/* <!--Portfolio Item--> */}
            <article className="ptf-work ptf-work--style-1">
              <div className="ptf-work__media">
                <Link className="ptf-work__link" to="/works-showcase"></Link>
                <img
                  src={`assets/img/portfolio/${val.img}.png`}
                  alt="portfolio"
                  loading="lazy"
                />
              </div>
              <div className="ptf-work__meta">
                <div className="ptf-work__category">{val.categorie}</div>
                <h4 className="ptf-work__title">
                  <Link to="/works-showcase">{val.title}</Link>
                </h4>
              </div>
            </article>
          </div>
        </div>
      ))}
    </>
  )
}

export default RelatedPortfolio
