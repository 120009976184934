import React, { useEffect } from 'react'
import Routes from './router/Routes'
import ScrollToTop from './components/ScrollToTop'
import AOS from 'aos'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'aos/dist/aos.css'
import { Helmet } from 'react-helmet'
// import { jarallax } from "jarallax";
import AnimatedCursor from 'react-animated-cursor'

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    })
  }, [])
  return (
    <>
      <Helmet>
        <title>Highline Developers</title>
        <meta name="description" content="Highline Developers Digital Agency" />
        <meta name="keywords" content="digital agency" />
      </Helmet>
      {/* End React Helmet for SEO */}

      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="220, 53, 69"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
      />
      {/* End Animated Cursor */}

      <ScrollToTop />
      {/* End Scroll To Top */}

      <Routes />
      {/* End All Routes */}
    </>
  )
}

export default App
